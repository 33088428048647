/* eslint-disable no-param-reassign */
import React, { Fragment, useContext, useMemo } from 'react';
import dayjs from 'dayjs';

import { styled } from 'styles';
import MainLayout from 'components/MainLayout';
import Dropdown from 'components/Dropdown';
import Notification from './Notification';
import Context, { NotificationsContextProvider } from './context';

const NotificationsPage = () => {
  const { notifications, filter, setFilter, filterOptions } = useContext(Context);

  const filterText = useMemo(
    () =>
      !filter.length || filter.length === filterOptions.length
        ? 'All'
        : filter.map(item => filterOptions.find(option => option.value === item)?.text).join(', '),
    [filter, filterOptions]
  );

  const groupNotifications = useMemo(
    () =>
      notifications?.reduce((accum, current) => {
        const date = dayjs(current.date * 1000).format('MM/DD/YY');
        accum[date] = accum[date] ? [...accum[date], current] : [current];
        return accum;
      }, {}),
    [notifications]
  );

  const getNotificationsByDate = date => {
    return (
      <Fragment key={date}>
        <div className="notifications__date">{date}</div>
        <div className="notifications__list">
          {groupNotifications?.[date].map(item => (
            <Notification key={item.id} data={item} />
          ))}
        </div>
      </Fragment>
    );
  };

  return (
    <MainLayout>
      <StyledNotificationsPage className="notifications">
        <div className="notifications__header">
          <h4 className="notifications__title">Notifications</h4>
          <div className="notifications__controls">
            <StyledDropdown
              options={filterOptions}
              value={filter}
              onChange={setFilter}
              label="Show:"
              text={filterText}
              multiple
              optionAll
              buttonLike
            />
          </div>
        </div>
        <div className="notifications__content">
          {groupNotifications && Object.keys(groupNotifications).map(getNotificationsByDate)}
        </div>
      </StyledNotificationsPage>
    </MainLayout>
  );
};

export default () => (
  <NotificationsContextProvider>
    <NotificationsPage />
  </NotificationsContextProvider>
);

const StyledNotificationsPage = styled.div`
  .notifications {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__date {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__list {
      margin: 10px 0 10px;
    }
  }
  &__list {
    margin: 10px 0 0;
  }
`;

const StyledDropdown = styled(Dropdown)`
  min-width: auto;
  max-width: 300px;
  .dropdown-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dropdown-options {
    width: auto;
    right: 0;
  }
`;

import React, { createContext, useState, useEffect, useMemo } from 'react';

import notify from 'notify';
import { tryGetFirstError } from 'utils/requests';
import { DropdownOption } from 'components/Dropdown';
import { getNotifications } from './api';

interface ContextValues {
  notifications?: Array<NotificationPageItem>;
  filter: Array<string>;
  setFilter: React.Dispatch<React.SetStateAction<Array<string>>>;
  filterOptions: Array<DropdownOption>;
}

const filterOptions: DropdownOption[] = [
  { value: 'contactRequest', text: 'Contact requests' },
  { value: 'scheduleRequest', text: 'Schedule viewing' },
  { value: 'createOffer', text: 'New offers' },
  { value: 'createListing', text: 'New listings' }
];

const NotificationsContext = createContext({} as ContextValues);

export const NotificationsContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<NotificationPageItem> | undefined>();
  const [filter, setFilter] = useState<Array<string>>(filterOptions.map(item => item.value));
  const filteredNotifications = useMemo(() => {
    if (!filter.length || filter.length === filterOptions.length) {
      return notifications;
    }
    return notifications?.filter(item => filter.includes(item.type));
  }, [filter, notifications]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getNotifications();
        setNotifications(data);
      } catch (err) {
        notify(tryGetFirstError(err.response) || err.message);
      }
    })();
  }, []);

  const contextValues = {
    notifications: filteredNotifications,
    filter,
    setFilter,
    filterOptions
  };

  return (
    <NotificationsContext.Provider value={contextValues}>{children}</NotificationsContext.Provider>
  );
};

export default NotificationsContext;

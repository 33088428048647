import React, { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { styled } from 'styles';
import Button from 'components/Button';
import { formatCurrency } from 'utils/rifmFormatters';
import {
  ConatactRequestIcon,
  ListingAddedIcon,
  OfferAddedIcon,
  ScheduleViewRequestIcon
} from './notificationIcons';

interface NotificationProps {
  data: NotificationPageItem;
}

const Notification: React.FC<NotificationProps> = ({ data }) => {
  const [, setListingId] = useQueryParam('listingId', StringParam);
  const [, setWorkspaceTab] = useQueryParam('workspaceTab', StringParam);

  const icon = useMemo(() => {
    switch (data.type) {
      case 'contactRequest':
        return <ConatactRequestIcon className="notification__img" />;
      case 'createOffer':
        return <OfferAddedIcon className="notification__img" />;
      case 'createListing':
        return <ListingAddedIcon className="notification__img" />;
      case 'scheduleRequest':
        return <ScheduleViewRequestIcon className="notification__img" />;
      default:
        return <div className="notification__img" />;
    }
  }, [data.type]);

  const text = useMemo(() => {
    switch (data.type) {
      case 'contactRequest':
        return `${data.name}, ${data.email}, ${data.phone}`;
      case 'createOffer':
        return data.offerAmount
          ? `${formatCurrency(data.offerAmount)}, ${data.listingAddress}`
          : data.listingAddress;
      case 'createListing':
        return data.listingAddress;
      case 'scheduleRequest':
        return `${data.listingAddress}, ${data.name}, ${data.email}, ${data.phone}`;
      default:
        return null;
    }
  }, [data.email, data.listingAddress, data.name, data.offerAmount, data.phone, data.type]);

  const openWorkspace = () => {
    setListingId(data.listingId, 'replaceIn');
    setWorkspaceTab('offers', 'replaceIn');
  };

  return (
    <StyledNotification className="notification">
      <div className="notification__info">
        {icon}
        <div>
          {data.title}
          <div className="notification__listing">{text}</div>
        </div>
      </div>
      <div className="notification__btn-group">
        {(data.type === 'createOffer' || data.type === 'createListing') && (
          <Button className="notification__btn" onClick={openWorkspace}>
            View
          </Button>
        )}
        {/* <Button className="notification__btn">View →</Button>
            <Button className="notification__btn">View & Accept →</Button>
            <Button className="notification__btn">✓ Approve</Button>
            <Button className="notification__btn" secondary>✗ Reject</Button> */}
      </div>
    </StyledNotification>
  );
};

export default Notification;

const StyledNotification = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;

  & ~ & {
    border-top: 1px solid ${props => props.theme.colors.seashell};
  }

  .notification {
    &__info {
      display: flex;
      align-items: center;
    }
    &__img {
      width: 24px;
      height: 24px;
      margin: 0 13px 0 0;
      /* background: ${props => props.theme.colors.harborGray}; */
    }
    &__listing {
      color: ${props => props.theme.colors.grayDark};
    }
    &__btn {
      margin: 0 0 0 8px;
      height: 24px;
    }
  }
`;

import { fetchWithToken } from 'utils/fetch';

enum NotificationType {
  createOffer = 'New Offer added',
  createListing = 'New Listing added',
  scheduleRequest = 'New schedule view request',
  contactRequest = 'New contact request'
}

export async function getNotifications(): Promise<Array<NotificationPageItem>> {
  const response = await fetchWithToken('/notifications');
  const data = await response.json();

  return data.map(item => {
    const formData = typeof item.data === 'string' ? JSON.parse(item.data) : item.data;
    return {
      id: item.id,
      listingId: item.listing_id,
      type: item.type,
      date: item.created,
      title: NotificationType[item.type],
      listingAddress:
        item.address ||
        [item.data.address1, item.data.city, item.data.state, item.data.postalCode]
          .filter(Boolean)
          .join(', '),
      offerAmount: formData.offerAmount,
      name: formData.name,
      email: formData.email,
      phone: formData.phone
    };
  });
}
